<template>
  <div>
    <EditRecurringEventForm
      :initial-form-data="recurringEvent"
      :on-form-saved="onRecurringEventSaved"
    />
  </div>
</template>

<script>
import EditRecurringEventForm from '@/views/pages/locations/EditRecurringEventForm'
import store from '@/store'
import { BButton } from 'bootstrap-vue'

export default {
  components: {
    EditRecurringEventForm,
    BButton,
  },
  props: [
    'locationId',
  ],
  data() {
    return {
      recurringEvent: {
        location_id: this.locationId,
        name: 'sample name',
        day_of_week: '0',
        time: '12:00',
      },
    }
  },
  methods: {
    onRecurringEventSaved(apiResponse) {
      const self = this
      store.dispatch('auth/getAuthUser')
        .then(() => {
          // after saving a new location, redirect to its edit for where all the other options like services are
          self.$router.push({
            name: 'edit-location',
            params: { id: apiResponse.data.location_id },
          })
        })
    },
  },
}
</script>
